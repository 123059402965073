import React from 'react';
import PropTypes from 'prop-types';

import styles from './style.module.scss';

export const Proposition = ({ item }) => (
  <div className={styles.root}>
    <h4 className={styles.title}>{item.title}</h4>
    <p className={styles.content}>{item.content}</p>
  </div>
);

Proposition.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.string
  })
};
