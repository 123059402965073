import PropTypes from 'prop-types';
import React from 'react';

import styles from './style.module.scss';

export const Layout = ({ children }) => {
  return <div className={styles.layout}>{children}</div>;
};

Layout.propTypes = {
  children: PropTypes.array.isRequired
};
